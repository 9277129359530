import { addDays, addMonths, addQuarters, addWeeks, addYears, format, getISOWeek, isValid, parseISO } from 'date-fns';
import { DatePickerValue } from '../components/date-picker/date-picker';
import { TimePickerValue } from '../components/time-picker/time-picker';
import { YearMonth, YearWeek } from '../models';

export interface IDateAndTime {
  date: DatePickerValue;
  time: TimePickerValue;
}

// Extract date and time from a Date object into date and time picker values
export function extractDateAndTime(dateAndTime: Date | string | undefined): IDateAndTime | undefined {
  if (!dateAndTime) {
    return undefined;
  }

  const date = new Date(dateAndTime);

  return {
    date: toStartOfDay(date),
    time: formatDateTimePickerValue(date),
  };
}

export function addMinutes(time: TimePickerValue, minutes: number): TimePickerValue | undefined {
  if (!time || typeof time === 'string') {
    return undefined;
  }

  const newMinutes = time.minutes + minutes;

  if (newMinutes / 60 < 1) {
    return {
      hours: time.hours,
      minutes: newMinutes,
    };
  }

  const newHours = time.hours + Math.floor(newMinutes / 60);

  return {
    hours: newHours % 24,
    minutes: newMinutes % 60,
  };
}

// Combine a date picker and time picker value into a Date object
export function combineDateAndTime(date: DatePickerValue, time: TimePickerValue): Date | undefined {
  if (!date || !time || typeof time === 'string') {
    return undefined;
  }

  const workDate = new Date(date);
  workDate.setHours(time.hours);
  workDate.setMinutes(time.minutes);

  return workDate;
}

// Get a local date that has same year,month,day as input, but 0:0:0 in time.
export function toStartOfDay(value: Date): Date;
export function toStartOfDay(value?: string | Date): Date | undefined;
export function toStartOfDay(value: string | Date | undefined): Date | undefined {
  if (!value) {
    return undefined;
  }

  const date = typeof value === 'string' ? parseISO(value) : value;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

// Get a local date that has same year,month,day as input, but time a millisecond before midnight next day.
export function toEndOfDay(value: Date): Date;
export function toEndOfDay(value?: string | Date): Date | undefined;
export function toEndOfDay(value: string | Date | undefined): Date | undefined {
  if (!value) {
    return undefined;
  }

  const date = typeof value === 'string' ? parseISO(value) : value;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}

export function toDate(value?: string | Date): Date | undefined {
  if (!value) {
    return undefined;
  }

  if (typeof value === 'string') {
    const date = parseISO(value);
    return isValid(date) ? date : undefined;
  }
  return value;
}

export function today(): Date {
  return toStartOfDay(new Date());
}

export const defaultDateFormat = 'dd-MM-yyyy';

export function formatDateFromJSDate(value?: string | Date, dateFormat = defaultDateFormat, missingValue = '') {
  return formatDate(new Date(Number(value)));
}

export function formatDate(value?: string | Date, dateFormat = defaultDateFormat, missingValue = '') {
  if (!value) {
    return missingValue;
  }

  if (typeof value === 'string') {
    const date = parseISO(value);

    if (!isValid(date)) {
      return value;
    }

    return formatDateWithCustomDateFormat(date, dateFormat);
  }

  return formatDateWithCustomDateFormat(value, dateFormat);
}

function formatDateWithCustomDateFormat(value: Date, dateFormat: string): string {
  return dateFormat === '@timefromtoday' ? formatTimeFromToday(value) : format(value, dateFormat);
}

export function formatTimeFromToday(value: Date): string {
  const now = today();
  const then = toStartOfDay(value);

  if (then.getTime() === now.getTime()) {
    return 'I dag';
  }

  const periodInWeeksAndDays = describePeriodInWeeksAndDays(getDayDiff(then, now));

  if (then < now) {
    return `${periodInWeeksAndDays} siden`;
  }

  return `Om ${periodInWeeksAndDays}`;
}

export const defaultDateTimeFormat = 'dd-MM-yyyy HH:mm';
export function formatDateTime(value?: string | Date) {
  return formatDate(value, defaultDateTimeFormat);
}

export function formatPeriod(start?: Date | string, end?: Date | string, dateFormat = defaultDateFormat): string {
  return `${formatDate(start, dateFormat)} - ${formatDate(end, dateFormat)}`.trim();
}

export function formatYearWeek(value?: YearWeek): string {
  if (value && value.week && value.week !== 0 && value.year && value.year !== 0) {
    return `${value.week} (${value.year})`;
  }
  return '-';
}

function GetQuarter(value: Date) {
  const month = value.getMonth() + 1;
  return Math.ceil(month / 3);
}

export function formatYearWeekFromDate(value: Date): string {
  const week = getISOWeek(value);
  return `Uge ${week} i ${value.getFullYear()}`;
}

export function formatQuarterYearFromDate(value: Date): string {
  const quarter = GetQuarter(value);
  return `${quarter}. kvartal i ${value.getFullYear()}`;
}

export function formatWeek(value?: number, missingValue = '-'): string {
  if (value && value !== 0) {
    if (value === 1) return '1 uge';
    return `${value} uger`;
  }
  return missingValue;
}

export function getNumberOfDays(startDate: Date, endDate: Date, includeToday = true) {
  // One day in milliseconds.
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates.
  const diffInTime = endDate.getTime() - startDate.getTime();

  // Calculating the no. of days between two dates.
  const diffInDays = Math.round(diffInTime / oneDay) + (includeToday ? 1 : 0);

  return diffInDays;
}

export function formatMonth(value?: number): string {
  if (value) {
    switch (value) {
      case 1:
        return 'Januar';
      case 2:
        return 'Februar';
      case 3:
        return 'Marts';
      case 4:
        return 'April';
      case 5:
        return 'Maj';
      case 6:
        return 'Juni';
      case 7:
        return 'Juli';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'Oktober';
      case 11:
        return 'November';
      case 12:
        return 'December';
      default:
        return '-';
    }
  }
  return '-';
}

export function formatYearMonth(value?: YearMonth): string {
  if (value && value.year && value.year !== 0 && value.month && value.month !== 0) {
    return `${value.year.toString()} ${formatMonth(value.month)}`;
  }
  return '-';
}

export function formatMonthYear(value?: YearMonth): string {
  if (value && value.year && value.year !== 0 && value.month && value.month !== 0) {
    return `${formatMonth(value.month)} ${value.year.toString()}`;
  }
  return '-';
}

export function formatMonthAbbreviationYear(value?: YearMonth): string {
  if (value && value.year && value.year !== 0 && value.month && value.month !== 0) {
    return `${formatMonth(value.month).substr(0, 3)} ${value.year.toString()}`;
  }
  return '-';
}

export function daysToYearMonthWeekDayString(days?: number, returnOnlyExtensionIfOneYear?: boolean): string {
  if (!days) {
    return '0 dage';
  }

  if (days < 7) {
    return days === 1 ? '1 dag' : `${days} dage`;
  }

  if (days < 31) {
    const weeks = Math.round(days / 7);
    return weeks === 1 ? '1 uge' : `${weeks} uger`;
  }

  if (days < 365) {
    const months = Math.round(days / 30.4);
    return months === 1 ? '1 måned' : `${months} måneder`;
  }

  const years = Math.round(days / 365);

  if (years === 1 && returnOnlyExtensionIfOneYear) {
    return 'år';
  }

  return `${years} år`;
}

export function daysToYearWeekDayString(days?: number): string {
  if (!days) {
    return '0 dage';
  }

  const DaysPerWeek = 7;
  const WeeksPerYear = 52;

  let weeks = Math.floor(days / DaysPerWeek);

  if (weeks === 0) {
    return days === 1 ? '1 dag' : `${days} dage`;
  }

  const years = Math.floor(weeks / WeeksPerYear);
  weeks = years > 0 && weeks >= WeeksPerYear ? weeks % WeeksPerYear : weeks;

  let weekStr = '';
  if (weeks === 1) weekStr = '1 uge';
  else if (weeks > 0) weekStr = `${weeks} uger`;

  if (years === 0) {
    return weekStr;
  }

  if (weeks === 0) return `${years} år`;

  return `${years} år og ${weekStr}`;
}

export function formatDateTimePickerValue(dateTime: Date | string | undefined): { hours: number; minutes: number } | undefined {
  const dateObj = toDate(dateTime);
  if (dateObj === undefined) {
    return undefined;
  }
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  return { hours, minutes };
}

export function formatTimeHHMM(dateTime: { hours: number; minutes: number } | string | undefined): string | undefined {
  if (dateTime === undefined || dateTime === null) {
    return undefined;
  }

  if (typeof dateTime === 'string') {
    if (dateTime.length === 4 && dateTime.indexOf(':') === -1) {
      // Special case without colon
      return `${dateTime.slice(0, 2)}:${dateTime.slice(2, 4)}`;
    }
    return dateTime;
  }

  let hours = dateTime?.hours?.toString();
  let minutes = dateTime?.minutes?.toString();
  if (hours && hours.length < 2) {
    hours = `0${hours}`;
  }
  if (minutes && minutes.length < 2) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

export function compareTime(dateTime1: { hours: number; minutes: number } | string | undefined, dateTime2: { hours: number; minutes: number } | string | undefined): number {
  const formattedDateTime1 = formatTimeHHMM(dateTime1)?.replace(':', '');
  const formattedDateTime2 = formatTimeHHMM(dateTime2)?.replace(':', '');

  if (!formattedDateTime1 || !formattedDateTime2) {
    return NaN;
  }

  const dateTime1AsInt = parseInt(formattedDateTime1, 10);
  const dateTime2AsInt = parseInt(formattedDateTime2, 10);

  if (dateTime1AsInt > dateTime2AsInt) {
    return 1;
  }
  if (dateTime1AsInt < dateTime2AsInt) {
    return -1;
  }
  return 0;
}

export function yearToDates(firstDateOfYear: Date) {
  const end = addDays(addYears(firstDateOfYear, 1), -1);
  return [firstDateOfYear, end];
}

export function quarterToDates(firstDateOfQuarter: Date) {
  const end = addDays(addQuarters(firstDateOfQuarter, 1), -1);
  return [firstDateOfQuarter, end];
}

export function monthToDates(firstDateOfMonth: Date) {
  const end = addDays(addMonths(firstDateOfMonth, 1), -1);
  return [firstDateOfMonth, end];
}

export function weekToDates(firstDateOfweek: Date) {
  const end = addDays(addWeeks(firstDateOfweek, 1), -1);
  return [firstDateOfweek, end];
}

export function dateToDates(date: Date) {
  return [date, date];
}

/**
 *  Will in the case of keyboard typing '19-07-21' correct to '19-07-2021'
 */
export function correctCentury(value?: Date | string, todayDate = today()): Date | string | undefined {
  if (value === undefined || value === null || typeof value === 'string') {
    return value;
  }

  const fullYear = value.getFullYear();
  if (fullYear > 100) {
    return value;
  }

  const currentCentury = Math.floor(todayDate.getFullYear() / 100) * 100;
  value.setFullYear(currentCentury + fullYear);
  return value;
}

export function getDayDiff(startDate?: Date | string, endDate?: Date | string): number {
  const msInDay = 24 * 60 * 60 * 1000;

  const newStartDate = toDate(startDate);
  const newEndDate = toDate(endDate);

  if (!newStartDate || !newEndDate) {
    return 0;
  }

  return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
}

export function describePeriodInWeeksAndDays(numberOfDays: number): string {
  const weeks = Math.floor(numberOfDays / 7);
  const days = numberOfDays % 7;

  let durationText = '';

  if (weeks !== 0) {
    durationText = `${weeks + (weeks === 1 ? ' uge' : ' uger')} og `;
  }

  durationText += days + (days === 1 ? ' dag' : ' dage');

  return durationText;
}
