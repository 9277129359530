import { moduleClientFactory } from '../../api-service/module-client-factory';
import { companyAreaPathName, companyTasksEditPathName } from '../../areas/company/company-area-pathnames';
import { ContextType } from '../../api-service/clients';

export async function redirectToTask(taskId: string): Promise<string> {
  const jobcenterTaskClient = moduleClientFactory.createJobcenterTaskClient();
  const { taskContext } = await jobcenterTaskClient.getTaskContext({ id: taskId });

  switch (taskContext?.contextType) {
    case ContextType.Company:
      return `/standalone/${companyAreaPathName}/${taskContext.contextId}/${companyTasksEditPathName}/${taskId}`;
    case ContextType.Citizen:
      return `/standalone/citizen/${taskContext.contextId}/tasks/edit/${taskId}`;
    case ContextType.IncomingMail:
      return `/incoming-mail/${taskId}`;
    case ContextType.General:
      return `/general-task/${taskId}`;
    default:
      break;
  }

  return '';
}
