/* eslint-disable max-len */
import { Box } from '@mui/material';
import React from 'react';
import { PromptExecutionSettingsDto } from '../../api-service/clients';
import { CitationSourceType, IChatMessage, useChat } from '../../hooks/ai-search/use-chat';
import { ChatMessages } from './chat-messages';
import { ChatMessageInput } from './chat-message-input';
import { AssistantType } from './assistant-type';
import { useCitizenContext } from '../../areas/citizen/citizen-context';
import { mapCitationSourceTypeToCitationContentType } from './chat-mapper';
import { useChatErrorHandling } from './hooks/use-chat-error-handling';
import { useClearChatOnCitizenChange } from './hooks/use-clear-chat-on-citizen-change';

export interface ICitizenAssistantChatboxProps {
  settings: PromptExecutionSettingsDto;
  citizenId?: string;
  onSettingsChange: (property: keyof PromptExecutionSettingsDto, value: any) => void;
}

export function CitizenAssistantChatbox(props: ICitizenAssistantChatboxProps) {
  const { citizenId, settings, onSettingsChange } = props;
  const { messages, handlePrompt, isLoading, error, setChatFeedback, citationsTypes, setCitationsTypes, clearChat } = useChat(AssistantType.Citizen);

  function handleSubmitPrompt(inputString: string) {
    handlePrompt({ citizenId, content: inputString.trim(), contentTypes: citationsTypes.map(mapCitationSourceTypeToCitationContentType), customPromptSettings: settings });
  }

  useChatErrorHandling(error);
  useClearChatOnCitizenChange(clearChat, citizenId);

  async function handleChatFeedbackChange(msg: IChatMessage) {
    await setChatFeedback(msg);
  }

  const handleDataSourceChange = (type: CitationSourceType) => {
    if (citationsTypes.find(x => x === type) !== undefined) {
      setCitationsTypes(citationsTypes.filter(x => x !== type));
    } else {
      setCitationsTypes([...citationsTypes, type]);
    }
  };

  const citizenContext = useCitizenContext();

  const initialMessage =
    "Du kan nu søge specifik information om borgeren ud fra de data, der forefindes i borgerens journal. Hvis du ønsker vejledning i hvordan du udfører en handling i Fasit eller i forhold til lovgivning, skal du vælge fanen 'Generelt' herover.\n\n" +
    'Fasit-assistenten sammenfatter den ønskede information i sit svar. Den svarer på det, den bliver spurgt om. Vær derfor gerne så specifik som muligt i din forespørgsel.\n\n' +
    'Bemærk, at Fasit-assistenten IKKE kan vejlede specifikt i forhold til borgeren. Et spørgsmål som "Hvordan laver jeg en plan for denne borger?" vil ikke kunne besvares ordentligt. Det skyldes, at svaret udelukkende baseres sig på data i borgerens journal og ikke inkluderer data fra fx vejledninger og interne arbejdsgange.\n\n' +
    'Inkludér gerne så meget kontekst og information som muligt for at sikre at svaret er fyldestgørende.\n' +
    'Gode eksempler på forespørgsler er:\n' +
    '- Giv mig et sagsresumé af borgerens seneste sag\n' +
    '- Giv mig en liste af borgerens aktiviteter i kronologisk rækkefølge og inkluder status\n' +
    '- Giv mig en oversigt over borgerens seneste 10 samtaler';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', width: '100%', height: '-webkit-fill-available' }}>
      <Box pl={2}>
        <h3>
          Borgerspecifik søgning for {citizenContext.fullName} ({citizenContext.cpr})
        </h3>
      </Box>
      <ChatMessages initialMessage={initialMessage} isLoading={isLoading} messages={messages} onChatFeedbackChange={handleChatFeedbackChange} />
      <ChatMessageInput
        citationsTypes={citationsTypes}
        isLoading={isLoading}
        onClearChat={clearChat}
        onDataSourceChange={handleDataSourceChange}
        onSettingsChange={onSettingsChange}
        onSubmitPrompt={handleSubmitPrompt}
        settings={settings}
        assistantType={AssistantType.Citizen}
      />
    </Box>
  );
}
