import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Tooltip } from '../tooltip';
import { IconButton } from '../icon-button/icon-button';
import { ThumbsUpIcon } from '../icons/thumbs-up-icon';
import { ThumbsDownIcon } from '../icons/thumbs-down-icon/thumbs-down-icon';
import { CommentIcon } from '../icons/comment-icon';
import { CommentDotsIcon } from '../icons/comment-dots-icon';
import { IChatMessage } from '../../hooks/ai-search/use-chat';
import { FormControl } from '../form-control';
import { Container } from '../container';
import { Row } from '../row';
import { Cell } from '../cell';
import { MaxLengthTextField } from '../text-field';
import { FormDialog } from '../form-dialog';
import { Vote } from '../../api-service/clients';
import { LinearProgress } from '../linear-progress';

export interface IChatFeedbackProps {
  msg: IChatMessage;
  onChatFeedbackChange: (msg: IChatMessage) => Promise<void>;
}

export function ChatFeedback(props: IChatFeedbackProps) {
  const { msg, onChatFeedbackChange } = props;
  const [isUpdating, setIsUpdating] = useState(false);
  const [chatMessage, setChatMessage] = useState<IChatMessage>(msg);
  const [qualityComment, setQualityComment] = useState<string>('');
  const [qualityCommentDialogOpen, setQualityDialogOpen] = useState(false);

  useEffect(() => {
    setQualityComment(msg.qualityComment ?? '');
  }, []);

  const handleConfirm = async () => {
    setChatMessage({ ...chatMessage, qualityComment });
    setIsUpdating(true);
    await onChatFeedbackChange({ ...chatMessage, qualityComment });
    setIsUpdating(false);
    setQualityDialogOpen(false);
  };

  const handleCancel = () => {
    setQualityComment(msg.qualityComment ?? '');
    setQualityDialogOpen(false);
  };

  const handleUpvote = async () => {
    const updatedVote = chatMessage.vote === Vote.Good ? Vote.Unknown : Vote.Good;
    const updatedMessage = { ...chatMessage, vote: updatedVote };
    setChatMessage(updatedMessage);
    setIsUpdating(true);
    await onChatFeedbackChange(updatedMessage);
    setIsUpdating(false);
  };

  const handleDownVote = async () => {
    const updatedVote = chatMessage.vote === Vote.Bad ? Vote.Unknown : Vote.Bad;
    const updatedMessage = { ...chatMessage, vote: updatedVote };
    setChatMessage(updatedMessage);
    setIsUpdating(true);
    await onChatFeedbackChange(updatedMessage);
    setIsUpdating(false);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQualityComment(event.target.value);
  };

  if (msg.role !== 'Assistent') {
    return null;
  }

  return isUpdating ? (
    <LinearProgress show />
  ) : (
    <Box>
      <Tooltip title="Godt svar">
        <IconButton onClick={handleUpvote} sx={{ color: chatMessage.vote === Vote.Good ? 'green' : 'lightgray' }}>
          <ThumbsUpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Dårligt svar">
        <IconButton onClick={handleDownVote} sx={{ color: chatMessage.vote === Vote.Bad ? 'red' : 'lightgray' }}>
          <ThumbsDownIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Kommentar">
        <IconButton onClick={() => setQualityDialogOpen(true)} disabled={chatMessage.vote === undefined || chatMessage.vote === Vote.Unknown}>
          {chatMessage.qualityComment && chatMessage.qualityComment.length > 0 ? <CommentDotsIcon /> : <CommentIcon />}
        </IconButton>
      </Tooltip>
      <FormDialog
        open={qualityCommentDialogOpen}
        title="Angiv kommentar"
        confirmButtonText="Gem"
        cancelButtonText="Annullér"
        onConfirmClick={handleConfirm}
        onCancelClick={handleCancel}
        onClose={handleCancel}
        maxWidth="xl"
        sx={{
          minWidth: 900,
        }}
      >
        <FormControl fullWidth>
          <Container>
            <Box minWidth={800}>
              <Row>
                <Cell size="full">
                  <MaxLengthTextField
                    label="Beskrivelse"
                    name="followUpMessage"
                    value={qualityComment}
                    maxLength={5000}
                    minRows="5"
                    maxRows="15"
                    variant="outlined"
                    multiline
                    fullWidth
                    onChange={handleCommentChange}
                  />
                </Cell>
              </Row>
            </Box>
          </Container>
        </FormControl>
      </FormDialog>
    </Box>
  );
}
