/* istanbul ignore file */
import React, { useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { configureRankedSearchStore, RankedSearchStoreState } from '../../store/configure-ranked-search-store';
import { SearchDrawer } from './search-drawer';
import { RowActionProvider } from '../../../search/contextProviders/row-action-provider';
import { IRankedSearchState, IHaving } from '../../store/reducers/ranked-search-reducer';
import { NotifyListener } from '../notify-listener/notify-listener';
import { findUniverse } from './find-universe';
import { ISelectedFilter } from './filters/search-drawer-custom-filters';
import { useFeatureToggles } from 'feature-toggles';

export interface ISearchDrawerContainerProps {
  styleEmbeddedStartPage?: boolean;
}

function SearchDrawerContainerWrapper(props: ISearchDrawerContainerProps) {
  const dispatch = useDispatch();
  const { isFeatureEnabled } = useFeatureToggles();
  const { styleEmbeddedStartPage } = props;
  const rankedSearchState = useSelector<RankedSearchStoreState, IRankedSearchState>(state => state.rankedSearch);

  const { search, pathname } = useLocation();

  useEffect(() => {
    dispatch({ type: 'rankedSearch/executeGetDocumentTypesSaga' });
  }, [dispatch]);

  useEffect(() => {
    const universe = findUniverse(search, pathname) || 'citizen';
    dispatch({ type: 'rankedSearch/updateUniverse', universe });
  }, [dispatch, search, pathname]);

  useEffect(() => {
    // for some reason this is neede on dashboard should wb with [] instead
    /* istanbul ignore next */
    window.addEventListener<'keydown'>('keydown', onKeyDown);
    return () => {
      window.removeEventListener<'keydown'>('keydown', onKeyDown);
    };
  });

  function onKeyDown(event: KeyboardEvent) {
    if (event.altKey && event.code === 'KeyQ') {
      handleShowDrawer(true);
    }
    if (event.code === 'Escape') {
      handleShowDrawer(false);
    }
  }

  function handleShowDrawer(visible: boolean) {
    dispatch({ type: 'rankedSearch/showDrawer', showDrawer: visible });
  }

  function handleExecuteSearch(resetSearchResult = false, createCitizenEnabled = false) {
    dispatch({ type: 'rankedSearch/executeQuerySaga', resetSearchResult, createCitizenEnabled });
  }

  function handleUpdateHaving(havings: IHaving[]) {
    dispatch({ type: 'rankedSearch/updateHaving', havings });
  }
  function handleUpdateQuery(query: string) {
    dispatch({ type: 'rankedSearch/updateQuery', query });
  }
  function handleUpdateUniverse(u: string) {
    dispatch({ type: 'rankedSearch/updateUniverse', universe: u });
  }

  function handleCreateCitizen(cpr: string) {
    dispatch({ type: 'rankedSearch/getCitizenFromDfdgSaga', cpr });
  }

  function handleGetCompanyFromCvr(productionUnitIdentifier: string) {
    dispatch({ type: 'rankedSearch/getCompanyFromCvr', productionUnitIdentifier });
  }
  function handleCustomFiltersChange(selectedFilters: ISelectedFilter[]) {
    dispatch({ type: 'rankedSearch/customFiltersChangeSaga', selectedFilters });
  }

  function handleChangeTab() {
    dispatch({ type: 'rankedSearch/resetAll' });
  }

  return (
    <RowActionProvider>
      <SearchDrawer
        onUpdateUniverse={handleUpdateUniverse}
        onUpdateHaving={handleUpdateHaving}
        onUpdateQuery={handleUpdateQuery}
        onExecuteSearch={handleExecuteSearch}
        onShowDrawer={handleShowDrawer}
        onGetCitizenFromDfdg={handleCreateCitizen}
        onGetCompanyFromCvr={handleGetCompanyFromCvr}
        onCustomFiltersChange={handleCustomFiltersChange}
        rankedSearchState={rankedSearchState}
        styleEmbeddedStartPage={styleEmbeddedStartPage}
        onTabChange={handleChangeTab}
      />
    </RowActionProvider>
  );
}
const store = configureRankedSearchStore();
export function SearchDrawerContainer(props: ISearchDrawerContainerProps) {
  return (
    <Provider store={store}>
      <NotifyListener />
      <SearchDrawerContainerWrapper {...props} />
    </Provider>
  );
}
