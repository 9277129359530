import React from 'react';
import { Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { SettingsSlider } from './settings-slider';
import { PromptExecutionSettingsDto, ModelType } from '../../../api-service/clients';

export interface IPromptSettingsProps {
  promptSettings: PromptExecutionSettingsDto;
  showRagDocuments: boolean;
  onUpdateSettings: (property: keyof PromptExecutionSettingsDto, value: any) => void;
}

export function PromptSettings(props: IPromptSettingsProps) {
  const { promptSettings, onUpdateSettings, showRagDocuments } = props;
  return (
    <Box sx={{ width: 'calc(100% - 50px)', marginBottom: 2 }}>
      <Box sx={{ flex: 1, minWidth: 'calc(50% - 8px)', display: 'flex', alignItems: 'center', paddingLeft: 1, paddingBottom: 2 }}>
        <Box>
          <Box>Model</Box>
          <RadioGroup row defaultValue="default" name="radio-buttons-group">
            <FormControlLabel value="default" control={<Radio onChange={e => onUpdateSettings('modelType', 0)} />} label="Standard" />
            <FormControlLabel value="large" control={<Radio onChange={e => onUpdateSettings('modelType', 1)} />} label="Large" />
          </RadioGroup>
        </Box>
      </Box>
      {/* Row 1: Two columns */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        <Box sx={{ flex: 1, minWidth: 'calc(50% - 8px)' }}>
          <SettingsSlider onChangesSaved={value => onUpdateSettings('topP', value)} label="TopP" max={1} min={0} defaultValue={0.7} step={0.1} value={promptSettings.topP} />
        </Box>
        <Box sx={{ flex: 1, minWidth: 'calc(50% - 8px)', paddingLeft: 2 }}>
          <SettingsSlider
            onChangesSaved={value => onUpdateSettings('temperature', value)}
            label="Temperature"
            max={1}
            min={0}
            defaultValue={0.3}
            step={0.1}
            value={promptSettings.temperature}
          />
        </Box>
      </Box>

      {/* Row 2: Two columns */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
        <Box sx={{ flex: 1, minWidth: 'calc(50% - 8px)' }}>
          <SettingsSlider
            onChangesSaved={value => onUpdateSettings('presencePenalty', value)}
            label="PresencePenalty"
            max={2}
            min={0}
            defaultValue={0}
            step={0.1}
            value={promptSettings.presencePenalty}
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: 'calc(50% - 8px)', paddingLeft: 2 }}>
          <SettingsSlider
            onChangesSaved={value => onUpdateSettings('frequencyPenalty', value)}
            label="FrequencyPenalty"
            max={2}
            min={0}
            defaultValue={0}
            step={0.1}
            value={promptSettings.frequencyPenalty}
          />
        </Box>
      </Box>

      {/* Row 3: Slider and RadioGroup */}
      {showRagDocuments && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
          <Box sx={{ flex: 1, minWidth: 'calc(50% - 8px)' }}>
            <SettingsSlider
              onChangesSaved={value => onUpdateSettings('maxRagDocuments', value)}
              label="Antal dokumenter"
              max={4}
              min={1}
              defaultValue={1}
              step={1}
              value={promptSettings.maxRagDocuments}
            />
          </Box>
          <Box sx={{ flex: 1, minWidth: 'calc(50% - 8px)', paddingLeft: 2 }}>
            <SettingsSlider
              onChangesSaved={value => onUpdateSettings('documentScoreThreshold', value)}
              label="Minimum dokument score"
              max={1}
              min={0}
              defaultValue={0.62}
              step={0.01}
              value={promptSettings.documentScoreThreshold}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
