import { CitationSourceType } from '../../hooks/ai-search/use-chat';
import { CitationContentType } from '../../api-service/clients';

export function mapCitationSourceTypeToCitationContentType(sourceType: CitationSourceType): CitationContentType {
  switch (sourceType) {
    case CitationSourceType.FasitGuides:
      return CitationContentType.UserGuide;
    case CitationSourceType.IaDoduments:
      return CitationContentType.IaDocument;
    case CitationSourceType.LegalDocuments:
      return CitationContentType.LegalDocument;
    default:
      throw new Error(`Unknown CitationSourceType: ${sourceType}`);
  }
}
