import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { citizenUrlPathRegex } from './use-chat-citizen-context';

export const useClearChatOnCitizenChange = (clearChat: () => void, citizenId?: string) => {
  const history = useHistory();
  const prevCitizenId = useRef(citizenId);

  useEffect(() => {
    if (citizenId && prevCitizenId.current !== citizenId) {
      clearChat();
    }

    prevCitizenId.current = citizenId;

    const handleUnload = () => {
      clearChat();
    };

    window.addEventListener('beforeunload', handleUnload);

    const unlisten = history.listen(location => {
      const currentMatch = location.pathname.match(citizenUrlPathRegex);
      const currentCitizenId = currentMatch?.groups?.citizenId;

      if (!currentCitizenId || currentCitizenId !== prevCitizenId.current) {
        handleUnload();
      }
    });

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      unlisten();
    };
  }, [citizenId, history, clearChat, citizenUrlPathRegex]);
};
