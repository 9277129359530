/* eslint-disable max-len */
import { moduleClientFactory } from '../../api-service/module-client-factory';
import {
  citizenAreaPathName,
  citizenInterviewBookingPath,
  citizenInterviewPath,
  citizenJournalNote,
  journalNotePathName,
  journalNoteView,
  journalTabPathName,
  partialRecoveryPathName,
  planAndActivitiesContractEditPathName,
  citizenDetailsTabPathName,
  citizenPersonContactPathNameWithoutId,
  missingParticipationPathName,
  benefitExtensionPathName,
  planAndActivitiesTabPathName,
  planAndActivitiesView,
  cvJobsearchTabPathName,
  jobReferralDetailPath,
  unemploymentTabPathName,
  citizenNuphForm,
} from '../../areas/citizen/citizen-area-pathnames';
import {
  activityReimbursementEditPathName,
  associatedCaseWorkerGuidePathname,
  caseGuidePathname,
  offerAndActivityAreaPathName,
  viewActivityPathName,
  viewEvaluationPathName,
  viewOfferPathName,
} from '../../areas/offer-and-activity/offer-and-activity-area-pathnames';
import { joinURL } from '../../utilities';
import { redirectToTask } from './task-redirect';
import {
  companyRecruitmentsPathName,
  companyRecruitmentDetailsPathName,
  companyRecruitmentDetailsFromCandidatePathName,
  effortsAreaPathName,
} from '../../areas/efforts/efforts-area-pathnames';
import { companyNoteEditPathName } from '../../areas/company/company-area-pathnames';

export async function createUrlToActualResource(resourceName: string, resourceId: string, jdcBaseUrl: string, searchParams?: URLSearchParams): Promise<string | null> {
  switch (resourceName.toLowerCase()) {
    case 'si_interview':
      return joinURL(jdcBaseUrl, citizenInterviewPath, resourceId);
    case 'si_journalnote': {
      const citizenId = searchParams?.get('citizenId');
      if (!citizenId) {
        return joinURL(jdcBaseUrl, citizenJournalNote, resourceId);
      }
      return joinURL(jdcBaseUrl, 'standalone', citizenAreaPathName, citizenId, journalTabPathName, journalNotePathName, journalNoteView, resourceId);
    }
    case 'si_offer': {
      return joinURL(jdcBaseUrl, offerAndActivityAreaPathName, viewOfferPathName, resourceId);
    }
    case 'assessment': {
      const citizenId = searchParams?.get('citizenId');
      return `/standalone/citizen/${citizenId}/availability-assessment/view/${resourceId}`;
    }
    case 'account': {
      return '';
    }
    case 'si_accountnote': {
      return joinURL('/company/routing/company-note', resourceId);
    }
    case 'booking': {
      return joinURL(jdcBaseUrl, citizenInterviewBookingPath, '-', resourceId);
    }
    case 'companynote': {
      return joinURL(jdcBaseUrl, companyNoteEditPathName, resourceId);
    }
    case 'si_document': {
      const citizenId = searchParams?.get('citizenId');
      const editMode = searchParams?.get('edit');

      if (editMode === 'true') {
        return joinURL('/', 'standalone', citizenAreaPathName, citizenId || 'ERROR_NO_CITIZEN_ID', journalTabPathName, 'document', 'create', resourceId);
      }

      if (!citizenId) {
        return joinURL('/', 'citizendocument', resourceId);
      }
      const citizenpart = joinURL('/', 'standalone', citizenAreaPathName, citizenId, journalTabPathName);

      return `${citizenpart}?documentId=${resourceId}`;
    }
    case 'si_case': {
      const caseId = resourceId;
      const caseService = moduleClientFactory.createCitizenCaseClient();
      const caseInfo = await caseService.getCaseInfoForLink({ caseId });
      return `/standalone/citizen/${caseInfo.citizenId}/${caseGuidePathname}/${caseId}`;
    }
    case 'partialrecoveries': {
      const partialRecoveryId = resourceId;
      const caseService = moduleClientFactory.createCitizenSicknessBenefitPartialRecoveryClient();
      const partialRecovery = await caseService.getPartialRecoveryById({ partialRecoveryId });
      return `/standalone/citizen/${partialRecovery.partialRecovery?.citizenId}/case/${partialRecovery.partialRecovery?.caseId}/partial-recovery/${partialRecovery.partialRecovery?.id}`;
    }
    case 'citizendialog': {
      const citizenDialogId = resourceId;
      const citizenId = searchParams?.get('citizenId');
      return `/citizen/${citizenId}/citizenmessage/${citizenDialogId}`;
    }
    case 'si_associateuser': {
      const associatedCaseWorkerId = resourceId;
      const caseService = moduleClientFactory.createCitizenCaseClient();
      const associatedInfo = await caseService.getAssociatedCaseWorker({ associatedCaseWorkerId });

      const caseId = associatedInfo?.associatedCaseWorker?.caseId;
      const caseInfo = await caseService.getCaseInfoForLink({ caseId: caseId || '' });
      return `/standalone/citizen/${caseInfo.citizenId}/${caseGuidePathname}/${caseId}/${associatedCaseWorkerGuidePathname}/${associatedCaseWorkerId}`;
    }
    case 'si_evaluation': {
      return joinURL(jdcBaseUrl, offerAndActivityAreaPathName, viewActivityPathName, viewEvaluationPathName, resourceId);
    }
    case 'si_reimbursement':
    case 'reimbursement': {
      let categoryId = parseNumberStr(searchParams?.get('categoryId'));
      let activityId = searchParams?.get('activityId');

      if (!categoryId || !activityId) {
        const client = moduleClientFactory.createOfferAndActivityClient();
        const response = await client.getReimbursementsRedirectProperties({ reimbursementId: resourceId });
        categoryId = response.offerCategory;
        activityId = response.activityId;
      }

      if (activityId) {
        return joinURL(jdcBaseUrl, offerAndActivityAreaPathName, viewActivityPathName, activityId, activityReimbursementEditPathName, resourceId);
      }

      return '';
    }
    case 'si_integrationcontract': {
      const citizenId = searchParams?.get('citizenId');

      return joinURL(jdcBaseUrl, 'standalone', citizenAreaPathName, citizenId ?? 'NOTFOUND', planAndActivitiesContractEditPathName);
    }
    case 'si_task': {
      return await redirectToTask(resourceId);
    }
    case 'partial-recovery': {
      const citizenId = searchParams?.get('citizenId') || '';

      const path = partialRecoveryPathName.replace(':caseId', resourceId.toLocaleLowerCase()).replace(':partialRecoveryId?', '');
      return joinURL(jdcBaseUrl, citizenAreaPathName, citizenId.toLocaleLowerCase(), path);
    }
    case 'missing-participation': {
      const citizenId = searchParams?.get('citizenId') || '';
      const caseId = resourceId;
      const path = missingParticipationPathName.replace(':caseId', caseId.toLocaleLowerCase()).replace(':missingParticipationId?', '');
      return joinURL(jdcBaseUrl, citizenAreaPathName, citizenId.toLocaleLowerCase(), path);
    }
    case 'missing-participation-from-crm': {
      const client = moduleClientFactory.createCitizenSicknessBenefitMissingParticipationClient();

      const result = await client.getMissingParticipationById({ missingParticipationId: resourceId });

      const citizenId = result?.missingParticipation?.citizenId || '';
      const caseId = result?.missingParticipation?.caseId || '';
      const path = missingParticipationPathName.replace(':caseId', caseId.toLocaleLowerCase()).replace(':missingParticipationId?', resourceId);
      return joinURL(jdcBaseUrl, citizenAreaPathName, citizenId.toLocaleLowerCase(), path);
    }
    case 'contact': {
      return joinURL(jdcBaseUrl, citizenAreaPathName, resourceId, citizenDetailsTabPathName);
    }
    case 'contact_crm_only': {
      return '';
    }
    case 'si_personcontact': {
      const citizenPersonContactClient = moduleClientFactory.createCitizenMasterDataCitizenPersonContactClient();
      const response = await citizenPersonContactClient.getCitizenIdForCitizenPersonContact({ citizenPersonContactId: resourceId });
      const { citizenId } = response;
      if (citizenId) {
        return joinURL(jdcBaseUrl, citizenAreaPathName, citizenId, citizenPersonContactPathNameWithoutId, resourceId);
      }
      return '';
    }
    case 'si_placement': {
      return joinURL(jdcBaseUrl, offerAndActivityAreaPathName, viewActivityPathName, resourceId);
    }
    case 'si_recruitmentrequestcandidate': {
      return joinURL(jdcBaseUrl, effortsAreaPathName, companyRecruitmentsPathName, companyRecruitmentDetailsPathName, companyRecruitmentDetailsFromCandidatePathName, resourceId);
    }
    case 'si_benefitextensionassessment': {
      const benefitExtensionAssessmentId = resourceId;
      const client = moduleClientFactory.createCitizenSicknessBenefitBenefitExtensionAssessmentClient();
      const response = await client.getBenefitExtensionAssessmentData({ benefitExtensionAssessmentId });
      const caseId = response?.model?.caseId ?? '';
      const citizenId = response?.model?.citizenId ?? '';
      const path = benefitExtensionPathName.replace(':caseId', caseId.toLocaleLowerCase()).replace(':benefitExtensionId?', benefitExtensionAssessmentId);
      return joinURL(jdcBaseUrl, citizenAreaPathName, citizenId.toLocaleLowerCase(), path);
    }
    case 'benefit-extension': {
      let caseId = '';
      let benefitExtensionAssessmentId = '';
      let citizenId = searchParams?.get('citizenId') ?? '';
      const fromCase = searchParams?.get('fromCase') ?? undefined;

      const client = moduleClientFactory.createCitizenSicknessBenefitBenefitExtensionAssessmentClient();

      if (citizenId) {
        caseId = resourceId;
        const response = await client.getOpenBenefitExtensionAssessmentId({ citizenId, caseId });
        benefitExtensionAssessmentId = response?.benefitExtensionAssessmentId ?? '';
        if (!fromCase) {
          benefitExtensionAssessmentId = resourceId;
        }
      } else {
        benefitExtensionAssessmentId = resourceId;
        const response = await client.getBenefitExtensionAssessmentData({ benefitExtensionAssessmentId });
        caseId = response?.model?.caseId ?? '';
        citizenId = response?.model?.citizenId ?? '';
      }

      const path = benefitExtensionPathName.replace(':caseId', caseId.toLocaleLowerCase()).replace(':benefitExtensionId?', benefitExtensionAssessmentId);
      return joinURL(jdcBaseUrl, citizenAreaPathName, citizenId.toLocaleLowerCase(), path);
    }
    case 'si_plan': {
      const citizenId = searchParams?.get('citizenId');
      if (citizenId) {
        return joinURL(jdcBaseUrl, citizenAreaPathName, citizenId, planAndActivitiesTabPathName, planAndActivitiesView);
      }
      throw new Error('Could not load plan without citizenId');
    }
    case 'persongroupmarking': {
      const personGroupMarkingId = resourceId;

      const personGroupService = moduleClientFactory.createPersonGroupClient();
      const response = await personGroupService.getCitizenIdFromPersonGroupMarkingId({ personGroupMarkingId });

      if (response.citizenId) {
        return joinURL(jdcBaseUrl, citizenAreaPathName, response.citizenId, citizenDetailsTabPathName);
      }
      throw new Error('Could not load persongroupmarking without citizenId');
    }
    case 'cases.resumedwork': {
      const resumedWorkId = resourceId;
      const client = moduleClientFactory.createCitizenSicknessBenefitResumedWorkClient();
      const result = await client.getCitizenIdFromResumedWorkId({ resumedWorkId });
      return joinURL(jdcBaseUrl, citizenAreaPathName, result.citizenId, citizenDetailsTabPathName);
    }
    case 'si_jobreferral': {
      const client = moduleClientFactory.createCitizenJobReferralClient();
      const response = await client.getCitizenIdByJobReferralId({ jobReferralId: resourceId });

      if (response?.citizenId) {
        return joinURL(jdcBaseUrl, citizenAreaPathName, response?.citizenId, cvJobsearchTabPathName, jobReferralDetailPath.replace(':id', resourceId));
      }
      throw new Error('Could not load jobreferral without citizenId');
    }
    case 'nuph': {
      const client = moduleClientFactory.createCitizenUnemploymentBenefitClient();
      const response = await client.getCitizenIdByNuphId({ nuphId: resourceId });
      if (response?.citizenId) {
        return joinURL(jdcBaseUrl, citizenAreaPathName, response?.citizenId, unemploymentTabPathName, citizenNuphForm.replace(':id', resourceId));
      }
      throw new Error('Could not load nuph without citizenId');
    }
    case 'nuph-response': {
      const client = moduleClientFactory.createCitizenUnemploymentBenefitClient();
      const response = await client.getCitizenAndNuphIdByNuphResponseId({ nuphResponseId: resourceId });
      if (response?.citizenId && response?.nuphId) {
        return joinURL(jdcBaseUrl, citizenAreaPathName, response.citizenId, unemploymentTabPathName, citizenNuphForm.replace(':id', response.nuphId));
      }
      throw new Error('Could not load nuph without citizenId');
    }
    default:
      return '';
  }
}

function parseNumberStr(str: string | undefined | null): number | undefined {
  if (str) {
    return Number(str);
  }
  return undefined;
}
