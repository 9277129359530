import { useState, useEffect } from 'react';
import { useErrorSnackbar } from '../../../hooks';
import { INotifierMessage } from '../../notifier/interfaces';

export function useChatErrorHandling(error: string | undefined) {
  const [errorMsg, setErrorMsg] = useState<INotifierMessage | undefined>(undefined);

  useEffect(() => {
    if (error) {
      const newErrorMsg = { message: error, severity: 'error' } as INotifierMessage;
      setErrorMsg(newErrorMsg);
    } else {
      setErrorMsg(undefined);
    }
  }, [error]);

  useErrorSnackbar(errorMsg);

  return errorMsg;
}
