import React from 'react';
import { Box } from '@mui/material';
import { PromptExecutionSettingsDto } from '../../api-service/clients';
import { CitationSourceType, IChatMessage, useChat } from '../../hooks/ai-search/use-chat';
import { ChatMessages } from './chat-messages';
import { ChatMessageInput } from './chat-message-input';
import { AssistantType } from './assistant-type';
import { mapCitationSourceTypeToCitationContentType } from './chat-mapper';
import { useChatErrorHandling } from './hooks/use-chat-error-handling';

export interface IFasitAssistantChatboxProps {
  settings: PromptExecutionSettingsDto;
  onSettingsChange: (property: keyof PromptExecutionSettingsDto, value: any) => void;
}

export function FasitAssistantChatbox(props: IFasitAssistantChatboxProps) {
  const { settings, onSettingsChange } = props;
  const { messages, handlePrompt, isLoading, error, setChatFeedback, citationsTypes, setCitationsTypes, clearChat } = useChat(AssistantType.Fasit);

  useChatErrorHandling(error);

  function handleSubmitPrompt(inputString: string) {
    handlePrompt({ content: inputString.trim(), contentTypes: citationsTypes.map(mapCitationSourceTypeToCitationContentType), customPromptSettings: settings });
  }

  async function handleChatFeedbackChange(msg: IChatMessage) {
    await setChatFeedback(msg);
  }

  const handleDataSourceChange = (type: CitationSourceType) => {
    if (citationsTypes.find(x => x === type) !== undefined) {
      setCitationsTypes(citationsTypes.filter(x => x !== type));
    } else {
      setCitationsTypes([...citationsTypes, type]);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', width: '100%', height: '-webkit-fill-available' }}>
      <ChatMessages isLoading={isLoading} messages={messages} onChatFeedbackChange={handleChatFeedbackChange} />
      <ChatMessageInput
        citationsTypes={citationsTypes}
        isLoading={isLoading}
        onClearChat={clearChat}
        onDataSourceChange={handleDataSourceChange}
        onSettingsChange={onSettingsChange}
        onSubmitPrompt={handleSubmitPrompt}
        settings={settings}
        assistantType={AssistantType.Fasit}
      />
    </Box>
  );
}
