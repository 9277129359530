import React from 'react';
import { Box, Slider } from '@mui/material';

import { ColorPalette } from '../../config';
import { TextField } from '../../text-field';

export interface ISettingsSliderProps {
  onChangesSaved: (value: number | number[]) => void;
  value?: number;
  max: number;
  min: number;
  defaultValue: number;
  step: number;
  label: string;
}

export function SettingsSlider(props: ISettingsSliderProps) {
  const { onChangesSaved, label, value, defaultValue } = props;
  return (
    <Box sx={{ marginLeft: 2, marginRight: 2, width: '100%' }}>
      <Box>{label}</Box>
      <Box sx={{ display: 'flex' }}>
        <Slider
          size="small"
          sx={{ marginRight: 2, marginTop: 1 }}
          valueLabelDisplay="auto"
          onChange={(event: React.SyntheticEvent | Event, newValue: number | number[]) => onChangesSaved(newValue)}
          {...props}
        />

        <Box
          sx={{
            width: '35px',
            height: '35px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${ColorPalette.AreaBoxBorder}`,
            borderRadius: '4px',
            backgroundColor: '#f6f8f9',
          }}
        >
          {value ?? defaultValue}
        </Box>
      </Box>
    </Box>
  );
}
