import { useLocation } from 'react-router';

export const citizenUrlPathRegex = /\/(?<context>citizen)\/(?<citizenId>[a-z0-9-]{36})?/i;

export default function useChatCitizenContext() {
  const { pathname } = useLocation();
  const match = pathname.match(citizenUrlPathRegex);
  if (match && match.groups) {
    const { context, citizenId }: { context?: string; citizenId?: string } = match.groups;
    return { context, citizenId };
  }
  return {};
}
