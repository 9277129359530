/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { createUrlToActualResource } from './resource-url-builder';
import { useCrmHostUrlContext } from './crm-host-url-context';
import { win } from '../../utilities';

export function ResourceRedirect() {
  const { url: crmHostUrl } = useCrmHostUrlContext();
  const { resourceName } = useParams<{ resourceName: string }>();
  const searchParams = new URLSearchParams(win.location.search);
  const resourceId = searchParams.get('resourceId');
  const history = useHistory();

  let redirectUrl = '/notfound';

  useEffect(() => {
    async function redirectWithRoutePush() {
      if (resourceId && crmHostUrl) {
        // we dont currently support resource urls without IDs. Change this code when/if we need to support that.
        const createdResourceUrl = await createUrlToActualResource(resourceName, resourceId, '', searchParams);

        if (createdResourceUrl !== null) {
          redirectUrl = createdResourceUrl;
        }
        if (redirectUrl.startsWith(crmHostUrl)) {
          win.location.assign(redirectUrl);
        } else {
          history.push(redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`);
        }
      }
    }
    redirectWithRoutePush();
  }, [resourceId, crmHostUrl]);

  return null;
}
