import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { CancelToken } from 'axios';
import { useSnackbar } from 'notistack';
import { useApiOnRender, useErrorSnackbar, useBroadcastContext } from '../../hooks';
import { CitizenClient, GetCitizenHeaderResponse, ICitizenClient, CitizenHeader } from '../../api-service/clients';
import { secureAxiosInstance } from '../../api-service';
import { configuration } from '../../configuration';
import { InformationSnackbar } from '../../components/notifier';

export interface ICitizenContext extends CitizenHeader {
  isLoading: boolean;
}

const CitizenContext = React.createContext<ICitizenContext>({} as ICitizenContext);

export function WithTestCitizenContext(props: PropsWithChildren<{ context: ICitizenContext }>) {
  const { children, context } = props;
  return <CitizenContext.Provider value={context}>{children}</CitizenContext.Provider>;
}

export function WithCitizenContext(props: PropsWithChildren<{ citizenId: string }>) {
  const { citizenId, children } = props;
  const citizenClient: ICitizenClient = new CitizenClient(configuration.bffBase, secureAxiosInstance);

  const citizenApi = useApiOnRender(
    (cancelToken: CancelToken, id: string) => citizenClient.getCitizenHeader({ citizenId: id }, cancelToken),
    { citizenHeader: { citizenId } } as GetCitizenHeaderResponse,
    citizenId
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    async function callInformationUpdated() {
      if (!window.location.href.toLowerCase().includes('standalone')) {
        // only call on citizen overview
        const informationUpdatedResult = await citizenClient.syncCitizenInformation({ citizenId });
        if (informationUpdatedResult.informationUpdated) {
          enqueueSnackbar('Informationer på siden er opdateret. De nye oplysninger vil først blive vist når siden genindlæses.', {
            ...InformationSnackbar,
            onClick: () => closeSnackbar(),
          });
        }
      }
    }
    callInformationUpdated();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { subscribe: citizenHeaderSubscribe } = useBroadcastContext('ReloadCitizenHeader');
  citizenHeaderSubscribe(() => {
    citizenApi.reload(citizenId);
  });

  const { result, isLoading, isError, error } = citizenApi;
  const { citizenHeader } = result;
  const citizen = { ...citizenHeader, isLoading } as ICitizenContext;
  useErrorSnackbar(error);

  return <CitizenContext.Provider value={citizen}>{children}</CitizenContext.Provider>;
}

export function useCitizenContext() {
  return useContext(CitizenContext);
}
