import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material/';
import createStyles from '@mui/styles/createStyles';
import Collapse from '@mui/material/Collapse';
import makeStyles from '@mui/styles/makeStyles';
import { TypographySettings } from '../../../../components/config/typography-settings';
import { ColorPalette } from '../../../../components/config/color-palette';
import { Dimensions } from '../../../../components/config/dimensions';
import { isFeatureEnabled } from 'feature-toggles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: ColorPalette.WHITE,
      borderRadius: Dimensions.BorderRadius,
      padding: '0px 20px 20px 20px',
      border: '1px solid',
      borderColor: ColorPalette.AreaBoxBorder,
      marginTop: '10px',
    },
    heading: {
      fontSize: '1rem',
      fontWeight: TypographySettings.DefaultFontSemiBold,
    },
    text: {
      marginLeft: '10px',
    },
    iconStyling: {
      fontSize: '1.3rem',
      minWidth: '40px',
    },
    iconSpan: {
      marginTop: '3px',
      textAlign: 'center',
      width: '100%',
    },
    listItemPadding: {
      paddingTop: '0',
      paddingBottom: '0',
    },
  })
);

export interface ISearchHelpBoxProps {
  showHelpText?: boolean;
}

interface IHelpListItem {
  text: string;
  icon: string;
}

const items: IHelpListItem[] = [
  { text: 'Søgeord skal optræde (eks. +jens)', icon: '+' },
  { text: 'Søgeord må ikke optræde. F.eks. find poster hvor ordet jens findes, men hvor hansen ikke findes: jens -hansen', icon: '-' },
  { text: 'Søger efter delvis tekst. Kan anvendes i slutningen af et søgeord. F.eks. find Bauhaus: bauh*', icon: '*' },
  { text: 'Søger på en bestemt rækkefølge af ord. F.eks. "jens hansen" finder kun Jens Hansen, og ikke Jens Per Hansen', icon: '""' },
  { text: 'Ovenstående operatorer kan kombineres, eksempel på dette: find virksomheder der begynder med hovedstad og ikke ligger i Ishøj: hovedstad* -ishøj', icon: '' },
];

export function SearchHelpBox(props: ISearchHelpBoxProps) {
  const { showHelpText } = props;
  const classes = useStyles();

  return (
    <Collapse in={showHelpText}>
      <List className={classes.root}>
        <ListSubheader className={classes.heading}>Følgende operatorer kan bruges i søgningen</ListSubheader>
        {items.map((item, i) => (
          <ListItem alignItems="center" key={i} className={classes.listItemPadding}>
            <ListItemIcon className={classes.iconStyling}>
              <span className={classes.iconSpan}>{item.icon}</span>
            </ListItemIcon>
            <ListItemText className={classes.text} primary={<span style={{ fontSize: TypographySettings.SmallFontSize }}>{item.text}</span>} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
}
