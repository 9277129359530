import * as React from 'react';
import Box from '@mui/material/Box';

interface IChatTabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function ChatTabPanel(props: IChatTabPanel) {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
