import React, { useState } from 'react';
import { Theme, Box, Button, Tooltip, CircularProgress, Typography, Tabs, Tab, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IRankedSearchState, IHaving } from '../../store/reducers/ranked-search-reducer';
import { ColorPalette } from '../../../../components/config/color-palette';
import { RankedSearchResultItem } from './ranked-search-result';
import { getDocumentTypeDescriptor } from './utils/document-type-descriptor';
import { SearchHelpBox } from './search-help-box';
import { TextField } from '../../../../components/text-field/text-field';
import { useIsAllowed } from '../../../../authentication/use-is-allowed';
import { ResourceTypes, ResourceOperations } from '../../../../authentication/resources';
import { isValidProductionUnitIdentifier } from '../../../../utilities/production-unit-identifier-helper';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import { SearchDrawerCustomFilters, ISelectedFilter } from './filters/search-drawer-custom-filters';
import { useFeatureToggles } from '../../../../feature-toggles';

const useStyles = (enableNewJdc: boolean, styleEmbeddedStartPage?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      list: {
        minWidth: 900,
        padding: theme.spacing(2),
      },
      searchButton: {
        position: 'absolute',
        right: 0,
        // eslint-disable-next-line no-nested-ternary
        top: styleEmbeddedStartPage ? 10 : enableNewJdc ? 7 : 47,
        color: ColorPalette.WHITE,
        backgroundColor: ColorPalette.DeepGalaxy,
        fontSize: 19,
        minWidth: 0,
        borderRadius: 0,
        borderBottomLeftRadius: theme.shape.borderRadius,
        '&:hover': {
          backgroundColor: 'rgba(54, 70, 94, .8)',
        },
        zIndex: enableNewJdc ? 1000 : undefined,
      },
      createCitizenButton: {
        width: 'fit-content',
        marginTop: theme.spacing(2),
      },
      createCitizenIcon: {
        marginRight: theme.spacing(1),
      },
      createCompanyButton: {
        width: 'fit-content',
        marginTop: theme.spacing(2),
      },
      createCompanyIcon: {
        marginRight: theme.spacing(1),
      },
      longCellStyling: {
        maxWidth: '95%',
        flexBasis: '95%',
      },
      shortCellStyling: {
        maxWidth: '5%',
        flexBasis: '5%',
      },
    })
  )();

const useResultCountStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#666',
    },
  })
);

const drawerStyle = makeStyles(() =>
  createStyles({
    paper: {
      overflow: 'hidden',
    },
  })
);

export interface ISearchDrawer {
  styleEmbeddedStartPage?: boolean;
  rankedSearchState: IRankedSearchState;
  onShowDrawer: (visible: boolean) => void;
  onExecuteSearch: (resetSearchResult?: boolean, createCitizenEnabled?: boolean) => void;
  onCustomFiltersChange: (selectedFilters: ISelectedFilter[]) => void;
  onUpdateQuery: (query: string) => void;
  onUpdateHaving: (havings: IHaving[]) => void;
  onUpdateUniverse: (universe: string) => void;
  onGetCitizenFromDfdg: (cpr: string) => void;
  onGetCompanyFromCvr: (productionUnitIdentifier: string) => void;
  onTabChange: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
}

function TabPanel(props: TabPanelProps) {
  const { children } = props;
  return (
    <div role="tabpanel" style={{ backgroundColor: '#E9EDF1', width: '868px' }}>
      <Box p={3}>{children}</Box>
    </div>
  );
}

export function SearchDrawer(props: ISearchDrawer) {
  const {
    styleEmbeddedStartPage,
    rankedSearchState,
    onShowDrawer,
    onExecuteSearch,
    onUpdateQuery,
    onUpdateHaving,
    onUpdateUniverse,
    onGetCitizenFromDfdg,
    onGetCompanyFromCvr,
    onCustomFiltersChange,
    onTabChange,
  } = props;
  const { showDrawer, query, isLoading, universe, rankedSearchResult, totalHits, hasError, noResults, havings, hasMoreResults, isCprQuery, isUnauthorizedCprQuery, isCvrQuery } =
    rankedSearchState;
  const [autofocusCount, setAutofocusCount] = useState<number>(0);
  const [showHelpText, setShowHelpText] = useState(false);
  const [searchedInDfdg, setSearchedInDfdg] = useState(false);
  const [searchedInCvr, setSearchedInCvr] = useState(false);

  const userIsAuthorizedForCitizens = useIsAllowed([{ resourceType: ResourceTypes.Citizen, resourceOperation: ResourceOperations.Read }]);
  const userIsAuthorizedForJournals = useIsAllowed([{ resourceType: ResourceTypes.JournalNote, resourceOperation: ResourceOperations.Read }]);
  const { isFeatureEnabled } = useFeatureToggles();
  const characterLimit = 1000;

  function getHavingsForUniverse() {
    const currentAvailableUniverse = getCurrentAvailableUniverse();
    return havings.filter(x => x.universe === currentAvailableUniverse);
  }

  function openSearch() {
    onShowDrawer(true);
  }

  function closeSearch() {
    onShowDrawer(false);
  }
  function onSearch(resetSearchResult?: boolean) {
    onExecuteSearch(resetSearchResult, true);
  }

  function handleEnterSearch(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && query) {
      onSearch(true);
      setShowHelpText(false);
      setSearchedInDfdg(false);
      setSearchedInCvr(false);
    }
  }

  function handleSearch() {
    if (query) {
      onSearch(true);
      setShowHelpText(false);
      setSearchedInDfdg(false);
      setSearchedInCvr(false);
    }
  }

  function handleFetchMoreData() {
    setAutofocusCount(rankedSearchResult?.length ?? 0);
    onSearch();
    setSearchedInDfdg(false);
    setSearchedInCvr(false);
  }

  function isCitizenSearchChecked(): boolean {
    return getHavingsForUniverse().some(h => h.isChecked && h.value === 'citizen');
  }

  function showGetCitizenFromDfdgButton() {
    const citizenChecked = getHavingsForUniverse().some(h => h.isChecked && h.value === 'citizen');
    return citizenChecked && isCprQuery && !isUnauthorizedCprQuery;
  }

  function handleGetCitizenFromDfdg() {
    setSearchedInDfdg(true);
    onGetCitizenFromDfdg(query.trim());
  }

  function isCompanySearchChecked(): boolean {
    return getHavingsForUniverse().some(h => h.isChecked && h.value === 'company');
  }
  function showGetCompanyFromCvrButton() {
    const isCompanySearch = getHavingsForUniverse().some(h => h.isChecked && h.value === 'company');

    if (rankedSearchResult && rankedSearchResult?.[0]?.documentType !== 'CvrCompany') {
      return false;
    }

    return isCompanySearch && isCvrQuery;
  }

  function handleGetCompanyFromCvr() {
    const pNrSearchString = query.trim();
    if (isValidProductionUnitIdentifier(pNrSearchString)) {
      setSearchedInCvr(true);
      onGetCompanyFromCvr(pNrSearchString);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onUpdateQuery(event.target.value);
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    const actionHaving = getHavingsForUniverse().find(v => v.value === event.target.value);
    if (actionHaving) {
      actionHaving.isChecked = checked;
      onUpdateHaving(havings);
    }
  }

  function handleHelpClick() {
    setShowHelpText(!showHelpText);
  }

  const classes = useStyles(true, styleEmbeddedStartPage);
  const resultCount = useResultCountStyles();
  const drawerClasses = drawerStyle();

  // eslint-disable-next-line @typescript-eslint/ban-types
  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    const availableRankedSearchTabs = getAvailableRankedSearchUniverses();
    onUpdateUniverse(availableRankedSearchTabs[newValue]);
    onTabChange();
  }

  function getProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function getIsDisabled(having: IHaving): boolean | undefined {
    const checkedHavings = getHavingsForUniverse().filter(x => x.isChecked);
    return checkedHavings.length === 1 && checkedHavings[0].value === having.value;
  }

  function renderHavings() {
    const filteredHavings = getHavingsForUniverse();
    if (filteredHavings.length > 1) {
      return filteredHavings.map(having => (
        <Checkbox
          label={having.label}
          checked={having.isChecked}
          key={`${having.label}_${having.value}`}
          value={having.value}
          onChange={handleCheckboxChange}
          style={{ display: getIsDisabled(having) ? 'hidden' : 'inherit' }}
        />
      ));
    }

    const currentAvailaUniverse = getCurrentAvailableUniverse();
    return getDocumentTypeDescriptor(currentAvailaUniverse)?.types;
  }

  function getCurrentAvailableUniverse() {
    const index = getSelectedTabIndex();
    const availableUniverses = getAvailableRankedSearchUniverses();
    return availableUniverses[index];
  }

  function searchHasError(): boolean {
    if (isSearchStringToLong()) {
      return true;
    }
    return hasCheckedHavings();
  }

  function hasCheckedHavings() {
    return !havings.some(h => h.universe === universe && h.isChecked);
  }

  function isSearchStringToLong() {
    return query.length >= characterLimit;
  }

  function getErrorText() {
    if (hasCheckedHavings()) {
      return 'Mindst en resultattype skal være tilvalgt';
    }
    if (isSearchStringToLong()) {
      return 'Søgestrengen må max være 1000 tegn';
    }
    return null;
  }

  function getSelectedTabIndex() {
    const index = getAvailableRankedSearchUniverses().findIndex(x => x === universe);
    return index !== -1 ? index : 0;
  }

  function renderDfdg() {
    return searchedInDfdg ? (
      <Box ml={4} mt={1}>
        <Typography classes={resultCount} variant="subtitle1">
          Borgeren findes ikke i dfdg
        </Typography>
      </Box>
    ) : (
      <Button onClick={handleGetCitizenFromDfdg} color="primary" variant="outlined" className={classes.createCitizenButton}>
        <FontAwesomeIcon className={classes.createCitizenIcon} icon={faPlus} />
        Hent denne borger i Dfdg
      </Button>
    );
  }

  function renderGetFromCvrButton() {
    return searchedInCvr ? (
      <Box ml={4} mt={1}>
        <Typography classes={resultCount} variant="subtitle1">
          Virksomheden findes ikke i CVR registeret
        </Typography>
      </Box>
    ) : (
      <Button onClick={handleGetCompanyFromCvr} color="primary" variant="outlined" className={classes.createCompanyButton}>
        <FontAwesomeIcon className={classes.createCompanyIcon} icon={faPlus} />
        Hent denne virksomhed fra CVR registeret
      </Button>
    );
  }
  function getAvailableRankedSearchUniverses() {
    const rankedSearchUniverses: string[] = [];

    if (userIsAuthorizedForCitizens) {
      rankedSearchUniverses.push('citizen');
    }
    rankedSearchUniverses.push('company');

    if (userIsAuthorizedForJournals) {
      rankedSearchUniverses.push('journalnote');
    }

    rankedSearchUniverses.push('task');

    return rankedSearchUniverses;
  }

  return (
    <>
      <Tooltip title="alt+q" placement="left" disableInteractive>
        <Button size="large" aria-label="Søg" value="search" onClick={openSearch} className={classes.searchButton}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Tooltip>
      <Drawer classes={drawerClasses} anchor="right" open={showDrawer} onClose={closeSearch} style={{ overflow: 'hidden' }}>
        <div className={clsx(classes.list)} role="presentation" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box mb={1}>
            <Box display="flex">
              <Box display="flex" flexGrow={2}>
                <Typography variant="h5">Fasitsøgning</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Tabs value={getSelectedTabIndex()} onChange={handleTabChange}>
              {userIsAuthorizedForCitizens && <Tab label="Borgere" {...getProps(0)} />}
              <Tab label="Virksomheder" {...getProps(1)} />
              {userIsAuthorizedForJournals && <Tab label="Journalnotater" {...getProps(2)} />}
              <Tab label="Opgaver og Adviser" {...getProps(3)} />
            </Tabs>
          </Box>
          <TabPanel>
            <>
              {renderHavings()}
              <Box mt={4} />
              <Grid container>
                <Grid item className={classes.longCellStyling}>
                  <TextField
                    error={searchHasError()}
                    disabled={hasCheckedHavings()}
                    errorText={getErrorText()}
                    variant="outlined"
                    autoFocus
                    onChange={handleChange}
                    label=""
                    multiline={false}
                    onKeyDown={handleEnterSearch}
                    inputProps={{ maxLength: characterLimit }}
                    InputProps={{
                      endAdornment: (
                        <IconButton disabled={hasCheckedHavings()} onClick={handleSearch} aria-label="Søg" color="primary" tabIndex={-1} size="large">
                          <FontAwesomeIcon icon={faSearch} />
                        </IconButton>
                      ),
                      placeholder: 'Hvad søger du?',
                      style: { backgroundColor: 'white' },
                    }}
                  />
                </Grid>
                <Grid item className={classes.shortCellStyling}>
                  <Tooltip title="Hjælp til søgning">
                    <IconButton style={{ marginTop: '6px', marginLeft: '6px' }} tabIndex={-1} onClick={handleHelpClick} aria-label="Hjælp" color="primary" size="large">
                      <FontAwesomeIcon icon={faInfoCircle} size="xs" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item className={classes.longCellStyling}>
                  <SearchDrawerCustomFilters
                    filters={rankedSearchState?.filterFields}
                    onFilterChange={onCustomFiltersChange}
                    selectedHavings={getHavingsForUniverse().filter(x => x.isChecked)}
                  />
                </Grid>
                <Grid item className={classes.longCellStyling}>
                  <SearchHelpBox showHelpText={showHelpText} />
                </Grid>
              </Grid>
            </>
          </TabPanel>
          {!noResults && rankedSearchResult && (
            <Box ml={4} mt={1}>
              <Typography classes={resultCount} variant="subtitle1">{`Antal resultater: ${totalHits}`}</Typography>
            </Box>
          )}
          {isCitizenSearchChecked() &&
            noResults &&
            (showGetCitizenFromDfdgButton() ? (
              renderDfdg()
            ) : (
              <Box ml={4} mt={1}>
                <Typography classes={resultCount} variant="subtitle1">
                  Ingen borger resultater
                </Typography>
              </Box>
            ))}
          {noResults &&
            isCompanySearchChecked() &&
            (showGetCompanyFromCvrButton() ? (
              renderGetFromCvrButton()
            ) : (
              <Box ml={4} mt={1}>
                <Typography classes={resultCount} variant="subtitle1">
                  Ingen virksomheds resultater
                </Typography>
              </Box>
            ))}
          {hasError && (
            <Box ml={4} mt={1}>
              <Typography variant="subtitle1" color="error">
                Der er sket en fejl. Verificer dit søgekriterie og prøv igen.
              </Typography>
            </Box>
          )}
          <div id="scrollableDiv" style={{ overflow: 'auto' }}>
            <InfiniteScroll
              scrollThreshold="200px"
              hasMore
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              next={() => {}}
              dataLength={rankedSearchResult?.length ?? 0}
              scrollableTarget="scrollableDiv"
              loader={
                totalHits > 0 &&
                hasMoreResults && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button fullWidth variant="text" onClick={handleFetchMoreData}>
                      Hent flere
                    </Button>
                  </div>
                )
              }
            >
              {(rankedSearchResult ?? []).map((x, i) => {
                if (autofocusCount > 0 && i === autofocusCount) {
                  return (
                    <RankedSearchResultItem autoFocus key={x.documentId} title={x.title} description={x.description} documentId={x.documentId} documentType={x.documentType} />
                  );
                }

                return <RankedSearchResultItem key={x.documentId} title={x.title} description={x.description} documentId={x.documentId} documentType={x.documentType} />;
              })}
            </InfiniteScroll>
          </div>
          {isLoading && (
            <CircularProgress
              size={36}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </div>
      </Drawer>
    </>
  );
}
