/* istanbul ignore file */
import React, { useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { faInfoCircle, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { Typography } from '../typography';
import { ColorPalette } from '../config';
import { DrawerContentBlock, Drawer } from '../drawer';
import { useGuideLinks } from '../guide-links/use-guide-links';
import { GuideLinkGuideId } from '../../api-service/clients';

export interface IHelpTextProps {
  helpText: IHelpText[];
  className?: string;
  sx?: BoxProps['sx'];
  showDetailsWithBullets?: boolean;
  guideLinksProps?: {
    citizenId?: string;
    guideId?: GuideLinkGuideId;
  };
}

export interface IHelpTextByTargetGroupId {
  targetGroupId: string;
  texts: IHelpText[];
}
interface IDetailsProps {
  listHeader?: string;
  listItems: string[];
}
export interface IHelpText {
  header: string;
  paragraphs?: string[];
  details?: IDetailsProps[];
  links?: { url: string; linkText: string }[];
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      cursor: 'pointer',
      padding: 5,
      backgroundColor: 'white',
      zIndex: 1000,
      position: 'absolute',
      right: 0,
      top: '8.2rem',
      borderBottomLeftRadius: '4px',
      borderTopLeftRadius: ' 4px',
    },
    icon: {
      margin: 5,
      position: 'relative',
      width: 48,
      height: 48,
    },
    fontAwesomeIconOverlay: {
      bottom: 0,
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
    },
    helptextHeader: {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    helptextListHeader: {
      fontWeight: 'bold',
    },
    helptextListitem: {
      fontSize: '0.8rem',
    },
    helptextParagraph: {
      fontSize: '0.85rem',
    },
    helptextLink: {
      fontSize: '0.85rem',
    },
  })
);

function helptextContainsLinks(helpText: IHelpText[]): boolean {
  return helpText
    .map(x => x.links)
    .map(x => (x ? x.length : 0))
    .some(x => x > 0);
}

function RenderDetailsDefault({ details }: { details: IDetailsProps[] }) {
  const classes = useStyles();

  return (
    <dl>
      {details.map(detail => (
        <React.Fragment key={detail.listHeader}>
          <dt className={classes.helptextListHeader} />
          {detail.listItems.map(item => (
            <dd key={item} className={classes.helptextListitem}>
              {item}
            </dd>
          ))}
        </React.Fragment>
      ))}
    </dl>
  );
}

function RenderDetailsWithBullets({ details }: { details: IDetailsProps[] }) {
  const classes = useStyles();

  return (
    <Box>
      {details.map(detail => (
        <Box key={detail.listHeader}>
          {detail.listHeader && <Box sx={{ fontSize: '0.85rem' }}>{detail.listHeader}</Box>}
          <ul>
            {detail.listItems.map(item => (
              <li key={item} className={classes.helptextListitem}>
                {item}
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </Box>
  );
}

export function HelpText(props: IHelpTextProps): React.ReactElement {
  const classes = useStyles();
  const { helpText, showDetailsWithBullets } = props;

  return (
    <>
      {helpText.map(text => (
        <React.Fragment key={text.header}>
          <Typography className={classes.helptextHeader}>{text.header}</Typography>
          {text.paragraphs
            ? text.paragraphs.map(paragraph => (
                // eslint-disable-next-line react/jsx-indent
                <Typography key={paragraph} className={classes.helptextParagraph}>
                  {paragraph || <span>&nbsp;</span>}
                </Typography>
              ))
            : ''}
          {text.details && showDetailsWithBullets === false && <RenderDetailsDefault details={text.details} />}
          {text.details && showDetailsWithBullets && <RenderDetailsWithBullets details={text.details} />}
          {text.links ? (
            <>
              {text.links.map(link => (
                <div key={link.url}>
                  <a href={link.url} target="_blank" rel="noreferrer noopener" className={classes.helptextLink}>
                    {link.linkText}
                  </a>
                </div>
              ))}
            </>
          ) : (
            ''
          )}
          <br />
        </React.Fragment>
      ))}
    </>
  );
}

export function HelpTextMenu(props: IHelpTextProps): React.ReactElement {
  const { helpText, className, sx, showDetailsWithBullets = false, guideLinksProps } = props;

  const { citizenId, guideId } = guideLinksProps ?? {};
  const { links, headline } = useGuideLinks(citizenId, guideId);

  const defaultState: {
    helpTextDrawerVisible: boolean | undefined;
  } = { helpTextDrawerVisible: undefined };
  const [{ helpTextDrawerVisible }, setState] = useState(defaultState);
  function handleShowHelpTextDrawer() {
    setState({ helpTextDrawerVisible: true });
  }
  function handleHideHelpTextDrawer() {
    setState({ helpTextDrawerVisible: false });
  }

  const classes = useStyles();

  const showBox = (helpText && helpText.length > 0) || (links && links.length > 0) || helptextContainsLinks(helpText) || true;

  return (
    <>
      {showBox && (
        <Box className={clsx(classes.container, className)} sx={sx} tabIndex={0} role="button" onKeyPress={handleShowHelpTextDrawer} onClick={handleShowHelpTextDrawer}>
          {helpText && helpText.length > 0 && (
            <div className={classes.icon}>
              <FontAwesomeIcon size="3x" icon={faInfoCircle} color={ColorPalette.CityNightSkiesDark} />
            </div>
          )}
          {helptextContainsLinks(helpText) || (links && links.length) ? (
            <div className={classes.icon}>
              <FontAwesomeIcon className={classes.fontAwesomeIconOverlay} size="3x" icon={faCircle} color={ColorPalette.HotAurora} />
              <FontAwesomeIcon className={classes.fontAwesomeIconOverlay} size="2x" icon={faLink} color="white" style={{ fontSize: 22 }} />
            </div>
          ) : (
            ''
          )}
        </Box>
      )}
      <Drawer isOpen={helpTextDrawerVisible || false} onHide={handleHideHelpTextDrawer}>
        <DrawerContentBlock>
          <HelpText helpText={helpText} showDetailsWithBullets={showDetailsWithBullets} />

          {links && links.length > 0 && (
            <Box
              sx={{
                '& a': {
                  fontSize: '0.85rem',
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: '700',
                  fontSize: '1rem',
                }}
              >
                {headline}
              </Typography>
              {links.map(link => (
                <div key={link.id}>
                  <Tooltip title={link.tooltip} disableInteractive>
                    <a href={link.url} target="_blank" rel="noreferrer noopener">
                      {link.linkHeading}
                    </a>
                  </Tooltip>
                </div>
              ))}
            </Box>
          )}
        </DrawerContentBlock>
      </Drawer>
    </>
  );
}
