import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { getDate } from 'date-fns';
import { IChatMessage } from '../../hooks/ai-search/use-chat';
import { ChatMessageComponent } from './chat-message';
import { formatDate } from '../../utilities';

export interface IChatMessagesProps {
  messages: IChatMessage[];
  initialMessage?: string;
  isLoading: boolean;
  onChatFeedbackChange: (msg: IChatMessage) => Promise<void>;
}

export function ChatMessages(props: IChatMessagesProps) {
  const { messages, initialMessage, isLoading, onChatFeedbackChange } = props;
  const lastMessageIndex = messages && messages.filter(msg => msg.content).length - 1;

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const prevMessagesLength = useRef(messages.length);
  const prevLastMessageContentLength = useRef(messages[messages.length - 1]?.content.length || 0);

  useEffect(() => {
    if (messagesEndRef.current) {
      const lastMessage = messages[messages.length - 1];
      const prevLastMessageContentLengthValue = prevLastMessageContentLength.current;

      if (messages.length > prevMessagesLength.current || (lastMessage && lastMessage.content.length !== prevLastMessageContentLengthValue)) {
        messagesEndRef.current.scrollIntoView();
      }

      prevMessagesLength.current = messages.length;
      prevLastMessageContentLength.current = lastMessage?.content.length || 0;
    }
  }, [messages]);

  return (
    <Box sx={{ pr: 3, pt: 3, overflowY: 'auto', flexGrow: 1 }}>
      {!!initialMessage && (
        <ChatMessageComponent
          showCitations={false}
          message={{ content: initialMessage, userName: 'Borger assistent', timestamp: formatDate(new Date()) }}
          isAssistant
          onChatFeedbackChange={onChatFeedbackChange}
        />
      )}
      {messages
        .filter(msg => msg.content)
        .map((msg, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index}>
            <ChatMessageComponent
              showCitations={msg.citations && msg.citations?.length > 0 && !isLoading}
              message={msg}
              isAssistant={msg.role === 'Assistent'}
              onChatFeedbackChange={onChatFeedbackChange}
            />
            <span ref={lastMessageIndex === index ? messagesEndRef : null} />
          </span>
        ))}
    </Box>
  );
}
